/**
 * Enum listing relevant api endpoints consumed by this module
 *
 * @export
 * @enum {string}
 */
export enum AuthEndpoints {
  Identify = '/auth/identify',
  Refresh = '/auth/refresh',
  SignOut = '/auth/sign-out',
}
