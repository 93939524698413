export const SECS_FOR_10_MINS = 600; // 60 * 10
export const SECS_FOR_30_MINS = 1800; // 60 * 30
export const SECS_FOR_1_HOUR = 3600; // 60 * 60

// Sizes in pixels
export const SIZE_10_MINS = 6;
export const SIZE_30_MINS = 18; // SIZE_10_MINS * 3
export const SIZE_60_MINS = 36; // SIZE_30_MINS * 2
export const SIZE_CAL_HEADER = 25;
export const SIZE_CAL_TIME_OFFSET = -10;
export const SIZE_GRID_BUFFER = 7;
export const SIZE_LINE_THICKNESS = 1;

// Time Formats
export const HOURS_FORMAT_12_HR = 'h a'; // 4 am, 11 am, 4 pm
export const HOURS_FORMAT_24_HR = 'HH:mm'; // 04:00, 11:00, 16:00
export const TIME_FORMAT_12_HR = 'h:mm a'; // 4:00 am, 11:00 am, 4:00 pm
export const TIME_FORMAT_24_HR = 'HH:mm'; // 04:00, 11:00, 16:00

export const NUM_DAYS_PER_WEEK = 7;

export const maxScheduleNameLength = 128;
