import { LinkMeta } from './types';

/**
 * list of contributors to the repository, update as needed here.
 */
export const contributors: string[] = [
  'David Dearden',
  'Andrew Nagarajah',
  'Jon Bastien',
  'Ivana Erlich',
  'Sahil Tara',
  'Hudi Friedman',
  'Natalia Maximo',
];

/**
 * A list of major libraries we are using in the repository.
 */
export const libraries: LinkMeta[] = [
  {
    name: 'Bootstrap',
    url: 'https://getbootstrap.com',
  },
  {
    name: 'React',
    url: 'https://reactjs.org/',
  },
  {
    name: 'Font Awesome',
    url: 'https://fontawesome.com/',
  },
];
