import { IdentityProviders } from './AccountSettings.Accounts.types';

export const identityProviders: IdentityProviders = {
  facebook: {
    name: 'Facebook',
    imageSrc: '/icon-facebook.svg',
  },
  google: {
    name: 'Google',
    imageSrc: '/icon-google.svg',
  },
  microsoft: {
    name: 'Microsoft',
    imageSrc: '/icon-microsoft.svg',
  },
};
