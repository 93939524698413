import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';

export const prefix = 'ev' as IconPrefix;
export const iconName = 'globe' as IconName;
export const width = 24;
export const height = 24;
export const ligatures = [];
export const unicode = '';
export const svgPathData =
  'M20.9285035,10.8601361 C20.5127682,7.57076782 18.3216365,4.83471059 15.3454503,3.64230648 C16.3944383,5.6673038 17,8.67235871 17,12 C17,12.1520704 16.9987353,12.3034672 16.9962215,12.4541275 C18.9736842,12.015741 20.4579577,11.3883247 20.9285035,10.8601361 Z M20.9211723,13.1964288 C19.8610141,13.7478635 18.4647076,14.2089641 16.8792306,14.5268844 C16.6538514,16.8289028 16.1207697,18.8609941 15.3454503,20.3576935 C18.3045601,19.172131 20.4875985,16.4605785 20.9211723,13.1964288 Z M3.10296429,13.3657859 C3.58836952,16.5541891 5.74676893,19.1926959 8.65454975,20.3576935 C7.88866859,18.879214 7.35916332,16.8782983 7.12913699,14.610834 C5.52333518,14.3317144 4.14644262,13.9092752 3.10296429,13.3657859 Z M3.06564935,10.9074041 C3.35718942,11.2805411 4.16781668,11.7579761 5.40825505,12.1475117 C5.89667659,12.300891 6.43159753,12.4357216 7.00555318,12.5502212 C7.00186026,12.3678797 7,12.1844353 7,12 C7,8.67235871 7.60556171,5.6673038 8.65454975,3.64230648 C5.66411911,4.84041759 3.46627566,7.59699344 3.06564935,10.9074041 Z M14.9862915,12.8045103 C14.9953365,12.5396718 15,12.2713459 15,12 C15,6.98399319 13.4063972,3 12,3 C10.5936028,3 9,6.98399319 9,12 C9,12.2869698 9.00521598,12.5705617 9.01531448,12.8502229 C9.95340738,12.9482908 10.9557722,13 12,13 C13.0280488,13 14.0367397,12.9274936 14.9862915,12.8045103 Z M14.8252793,14.8441424 C13.9124489,14.9453996 12.9633249,15 12,15 C11.0208804,15 10.0760086,14.9581505 9.17890858,14.8773334 C9.6334669,18.4574369 10.8764702,21 12,21 C13.1269987,21 14.3742099,18.441712 14.8252793,14.8441424 Z M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z';

export const definition: IconDefinition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

export const evGlobe = definition;
